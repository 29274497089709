import { Injectable, OnDestroy } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '@masterdata/env/environment'
import { catchError, takeUntil } from 'rxjs/operators'
import { of, Subject } from 'rxjs'
import { MsSqlPaginatedData, Region } from '@agroone/entities'
import { LoggerService } from '@agroone-front/shared'

@Injectable({
  providedIn: 'root',
})
export class RegionService implements OnDestroy {
  public regions: Region[] = []
  private destroyed: Subject<void> = new Subject()

  constructor(private http: HttpClient, private logger: LoggerService) {}

  init(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.http
        .get(`${environment.baseUrl}/regions`)
        .pipe(
          takeUntil(this.destroyed),
          catchError((error) => {
            this.logger.error('Regions loading error', JSON.stringify(error))
            return of(this.regions)
          })
        )
        .subscribe((res: MsSqlPaginatedData<Region>) => {
          this.regions = res?.data
          this.logger.log('Regions initialized')
          resolve()
        })
    })
  }

  ngOnDestroy() {
    this.destroyed.next()
    this.destroyed.complete()
  }
}
