import { Component, Input } from '@angular/core'
import { MenuItem } from '@masterdata/app/shared/menu-sidebar/config/menu-items'

@Component({
  selector: 'md-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  templateUrl: './one-column.layout.html',
})
export class OneColumnLayoutComponent {
  @Input() menuContent: MenuItem[] = []

  sidebarExpanded: boolean = false

  updateExpandedStatus() {
    this.sidebarExpanded = !this.sidebarExpanded
  }
}
