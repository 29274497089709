import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { environment } from '@masterdata/env/environment'

@Component({
  selector: 'md-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  @Input() isExpanded: boolean = false
  @Output() toggleSidebar: EventEmitter<void> = new EventEmitter<void>()

  public hubUrl!: string

  ngOnInit() {
    this.hubUrl = environment.hubUrl
  }

  expandSidebar() {
    this.isExpanded = !this.isExpanded
    this.toggleSidebar.emit()
  }
}
