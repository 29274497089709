<div class="px-2">
  <div class="mt-3 ml-1">
    <a [href]="hubUrl" target="_self">
      <img src="assets/icons/svg/home-header-icon.svg" />
    </a>
  </div>
  <div class="flex mt-4">
    <span class="cursor-pointer" (click)="expandSidebar()">
      <span class="pi pi-bars"></span>
    </span>
    <div *ngIf="isExpanded" class="ml-4">
      <img src="assets/icons/svg/masterdata.svg" />
    </div>
  </div>
</div>
