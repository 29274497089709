import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router'

@Injectable({
  providedIn: 'root',
})
export class MdRouteReuseStrategy extends RouteReuseStrategy {
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {}

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return null
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    const shouldReuse = future.routeConfig === curr.routeConfig

    if (shouldReuse && curr.data.noReuse) {
      return false
    }

    return shouldReuse
  }
}
