import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { UserDto } from '@agroone/entities'
import { LoggerService, SharedUserService } from '@agroone-front/shared'
import { environment } from '@masterdata/env/environment'
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root',
})
export class UserService extends SharedUserService {
  override userApiUrl: string = `${environment.baseUrl}/users`

  constructor(protected override readonly http: HttpClient, protected override readonly logger: LoggerService) {
    super(http, logger)
  }

  public init(): Observable<UserDto> {
    return this.getCurrentUser()
  }
}
