import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { environment } from '@masterdata/env/environment'

// eslint-disable-next-line
declare var dT_
@Component({
  selector: 'md-root',
  template: '<router-outlet></router-outlet> <p-toast/>',
})
export class AppComponent {
  constructor(private http: HttpClient) {
    if (typeof dT_ !== 'undefined' && dT_.initAngularNg) {
      dT_.initAngularNg(this.http, HttpHeaders)
    }
    this.loadExternalScript(environment.dynatraceScriptSrc)
  }

  private loadExternalScript(scriptUrl: string): void {
    if (!scriptUrl) {
      return
    }

    const scriptElement = document.createElement('script')
    scriptElement.src = scriptUrl
    scriptElement.type = 'text/javascript'
    document.body.appendChild(scriptElement)
  }
}
