export const environment = {
  production: true,
  logLevel: 'WARN',
  dynatraceScriptSrc: '',
  serverLogLevel: 'WARN',
  baseUrl: 'https://apiv2.cldqlf.bonduelle.com',
  hubUrl: 'https://agroone.cldqlf.bonduelle.com',
  isLocal: false,
  cognitoDomain: 'https://ssoconnect.cldqlf.bnd-services.com',
  cognitoClientId: '4ui640sj1gqqf3fs64ft5mge6d',
}
