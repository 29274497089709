import { CommonModule } from '@angular/common'
import { ModuleWithProviders, NgModule } from '@angular/core'
import {
  NbActionsModule,
  NbContextMenuModule,
  NbLayoutModule,
  NbMenuModule,
  NbThemeModule,
  NbUserModule,
} from '@nebular/theme'

import { RouterModule } from '@angular/router'
import { FooterComponent, HeaderComponent } from './components'
import { OneColumnLayoutComponent } from './layouts'
import { CapitalizePipe, NumberWithCommasPipe, PluralPipe, RoundPipe, TimingPipe } from './pipes'
import { AccordionModule } from 'primeng/accordion'

const NB_MODULES = [AccordionModule, NbLayoutModule, NbMenuModule, NbUserModule, NbActionsModule, NbContextMenuModule]
const COMPONENTS = [HeaderComponent, FooterComponent, OneColumnLayoutComponent]
const PIPES = [CapitalizePipe, PluralPipe, RoundPipe, TimingPipe, NumberWithCommasPipe]

@NgModule({
  imports: [CommonModule, RouterModule, ...NB_MODULES],
  exports: [CommonModule, ...PIPES, ...COMPONENTS],
  declarations: [...COMPONENTS, ...PIPES],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot({
          name: 'default',
        }).providers,
      ],
    }
  }
}
