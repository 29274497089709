import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import { DialogModule } from 'primeng/dialog'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  standalone: true,
  imports: [DialogModule, TranslateModule, CommonModule],
})
export class ConfirmDialogComponent {
  public title?: string
  public message: string
  public style?: string

  constructor(private config: DynamicDialogConfig, private dialogRef: DynamicDialogRef) {
    this.title = config.data?.title
    this.message = config.data?.message
    this.style = config.data?.style
  }

  onConfirm(): void {
    this.dialogRef.close(true)
  }

  onCancel(): void {
    this.dialogRef.close(false)
  }
}
