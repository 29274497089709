import { ExtraOptions, RouterModule, Routes } from '@angular/router'
import { NgModule } from '@angular/core'
import { AccessDeniedComponent, LoginComponent, SharedLogoutComponent } from '@agroone-front/shared'

export const routes: Routes = [
  { path: 'access-denied', component: AccessDeniedComponent },
  {
    path: 'logout',
    component: SharedLogoutComponent,
  },
  {
    path: 'auth/login',
    component: LoginComponent,
  },
  {
    path: '',
    loadChildren: () => import('./pages/pages.module').then((m) => m.PagesModule),
  },
  { path: '**', redirectTo: '' },
]

const config: ExtraOptions = {
  useHash: false,
  onSameUrlNavigation: 'reload',
}

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
