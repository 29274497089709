<nb-layout>
  <nb-layout-column>
    <div
      class="h-screen w-full grid transition-all duration-300 ease-in-out"
      [class.expanded]="sidebarExpanded"
      id="layout-wrapper"
    >
      <div
        id="sidebar"
        class="h-screen bg-white shadow-lg transition-all duration-300 ease-in-out max-h-screen overflow-y-auto"
      >
        <md-header id="header" [isExpanded]="sidebarExpanded" (toggleSidebar)="updateExpandedStatus()"></md-header>

        <div class="menu-content mt-4">
          <a class="menu-link flex items-center font-bold py-3" routerLink="/">
            <img class="w-6 h-6" src="assets/icons/svg/dashboard.svg" />
            <span class="ml-2 link-text text-xs">Dashboard</span>
          </a>
          <ng-container *ngFor="let item of menuContent">
            <p-accordion
              *ngIf="item.children; else noChildren"
              (click)="sidebarExpanded === false ? updateExpandedStatus() : null"
            >
              <p-accordionTab [header]="sidebarExpanded ? item.title : ''">
                <ng-container *ngFor="let child of item.children">
                  <ng-container *ngTemplateOutlet="menuLink; context: { item: child }"></ng-container>
                </ng-container>
              </p-accordionTab>
            </p-accordion>
            <ng-template #noChildren>
              <ng-container *ngTemplateOutlet="menuLink; context: { item: item }"></ng-container>
            </ng-template>
          </ng-container>
        </div>

        <md-footer *ngIf="sidebarExpanded" id="footer" class="mt-3 flex justify-center"></md-footer>
      </div>
      <div class="py-6 px-5 bg-slate-200">
        <ng-content select="router-outlet"></ng-content>
      </div>
    </div>
  </nb-layout-column>
</nb-layout>

<ng-template #menuLink let-item="item">
  <a class="menu-link flex items-center py-3 font-bold" [routerLink]="item.link" routerLinkActive="active">
    <img class="w-6 h-6" [src]="'assets/icons/svg/' + item.icon + '.svg'" />
    <span class="ml-2 link-text text-xs">{{ item.title }}</span>
  </a>
</ng-template>
