import { Directive, EventEmitter, Output } from '@angular/core'

@Directive()
export abstract class AgrooneModalComponent<T> {
  @Output() onClose: EventEmitter<T> = new EventEmitter<T>()

  isLoading: boolean = false

  closeModal() {
    this.onClose.emit()
  }
}
