<div class="modal-box">
  <div class="modal-title">
    <h6 *ngIf="title" mat-dialog-title>{{ title }}</h6>
  </div>
  <div class="modal-content">
    <p>{{ message }}</p>
  </div>
  <div class="modal-action">
    <button class="btn" (click)="onCancel()">
      {{ 'NO' | translate }}
    </button>
    <button class="btn btn-primary" (click)="onConfirm()">
      {{ 'YES' | translate }}
    </button>
  </div>
</div>
